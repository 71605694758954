// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "AndOpenYourMouthToStart": "And open your mouth to start",
    "down": "Down",
    "faceDownVerification": "Face down Verification",
    "faceUpVerification": "Face up verification",
    "faceVerification": "Face verification",
    "faceVerificationAlreadyCompletedForThisUser": "Face verification already completed for this user",
    "faceVerificationCompleted": "Face verification completed",
    "faceVerificationFailed": "Face verification failed",
    "faceVerificationStatus": "Face verification status",
    "Failed": "Failed",
    "IN_VERIFICATION": "In verification",
    "left": "Left",
    "leftFaceVerification": "Left face verification",
    "multipleFacesDetected": "Multiple faces detected",
    "noFaceDetected": "No face detected",
    "NOT_STARTED": "Not started",
    "pleaseAlignYourFaceInsideTheCircle": "Please align your face inside the circle",
    "pleaseAlignYourFaceInsideTheCircleAndTryAgain": "Please align your face inside the circle and try again",
    "pleaseAllowCameraPermissionForVerifyingYourFace": "Please allow camera permission for verifying your face",
    "pleaseBlinkBothYourEyes": "Please blink both your eyes",
    "pleaseBlinkBothYourEyesAndOpenYourMouthToStart": "Please blink both your eyes and open your mouth to start",
    "pleaseEnsureThatOnlyOnePersonIsInTheCameraFrameAndTryAgain": "Please ensure that only one person is in the camera frame and try again",
    "pleaseFollowTheArrows": "Please follow the arrows",
    "lookAtCameraStraightAndTurnSlowly": "Look at camera straight and turn slowly",
    "pleaseLookDownSlowly": "Please look down slowly",
    "pleaseLookUpSlowly": "Please look up slowly",
    "pleaseTurnSlowlyTowardsYourLeft": "Please turn slowly towards your left",
    "pleaseTurnSlowlyTowardsYourRight": "Please turn slowly towards your right",
    "pleaseTurnSlowlyAndSlightly": "Please turn slowly and slightly",
    "redirectingToTheNextStep": "",
    "retry": "Retry",
    "right": "Right",
    "rightFaceVerification": "Right face verification",
    "straight": "Straight",
    "straightFaceVerification": "Straight face verification",
    "up": "Up",
    "VERIFIED": "Verified",
    "verificationCompleted": "Verification completed",
    "verificationFailed": "Verification failed",
  };