import { useEffect, useState } from "react"
import { Config } from "../Config";
import { useLocation } from "react-router-dom";

export const useFaceVerificationCheck = () => {
    const query = new URLSearchParams(useLocation().search);
    const cUserIdEncrypted = query.get("u");
    const ipAddressEncrypted = query.get("add");
    const apiUrl = Config.API_URL;
    const [isFaceVerificationCompleted, setIsFaceVerificationCompleted] = useState(false)

    useEffect(() => {
        console.log("face verification check");
        console.log("cUserIdEncrypted", cUserIdEncrypted);
        console.log("ipAddressEncrypted", ipAddressEncrypted);

        fetch(`${apiUrl}/api/users/verification/${cUserIdEncrypted}/${ipAddressEncrypted}`, { method: "GET" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log("Face verification response:", data);
                setIsFaceVerificationCompleted(data.isFaceVerified);
            })
            .catch((error) => {
                console.error("Error fetching face verification:", error);
            });
    }, [cUserIdEncrypted, ipAddressEncrypted, apiUrl]);

    return {
        isFaceVerificationCompleted
    };
};